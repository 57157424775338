"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry.js");
var _dictionary = require("@/config/dictionary");
var _send = _interopRequireDefault(require("../../case/service/send"));
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'division',
  data: function data() {
    var _ref;
    return _ref = {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      tableData: [],
      secretaryList: [],
      total: 0,
      //总页数
      conditionData: '',
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      wayList: [{
        name: '书面审理',
        id: '1'
      }, {
        name: '线下开庭',
        id: '2'
      }, {
        name: '视频开庭',
        id: '3'
      }],
      divisionLotVisible: false,
      //案件组庭批量弹窗显示
      divisionLotForm: {
        //案件组庭批量数据
        chiefArbitratorId: "",
        chiefArbitratorName: '',
        trialWay: ''
      },
      divisionLotRules: {
        chiefArbitratorId: [{
          required: true,
          message: '请选择首席仲裁员',
          trigger: 'change'
        }],
        trialWay: [{
          required: true,
          message: '请选择审理方式',
          trigger: 'change'
        }],
        secretaryId: [{
          required: true,
          trigger: 'change',
          message: '请选择处理人'
        }],
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写意见'
        }]
      },
      divisionRules: {
        secretaryId: [{
          required: true,
          trigger: 'change',
          message: '请选择处理人'
        }],
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写意见'
        }]
      },
      divisionVisible: false,
      //案件组庭弹窗显示
      divisionForm: {
        //案件组庭数据
        chiefArbitratorId: "",
        chiefArbitratorName: '',
        secondArbitratorId: '',
        secondArbitratorName: '',
        thirdArbitratorId: '',
        thirdArbitratorName: '',
        trialWay: ''
      }
    }, (0, _defineProperty2.default)(_ref, "divisionRules", {
      chiefArbitratorId: [{
        required: true,
        message: '请选择首席仲裁员',
        trigger: 'change'
      }],
      trialWay: [{
        required: true,
        message: '请选择审理方式',
        trigger: 'change'
      }]
    }), (0, _defineProperty2.default)(_ref, "caseId", ''), (0, _defineProperty2.default)(_ref, "caseNum", ''), (0, _defineProperty2.default)(_ref, "node", ''), (0, _defineProperty2.default)(_ref, "arbitratorList", []), (0, _defineProperty2.default)(_ref, "partyChoiceArb", {
      appArbitratorName: '',
      resArbitratorName: '',
      content: '',
      replyName: '',
      href: '',
      secretaryId: '',
      opinion: '',
      secretaryName: ''
    }), (0, _defineProperty2.default)(_ref, "allLoading", false), (0, _defineProperty2.default)(_ref, "sendCaseVisible", false), (0, _defineProperty2.default)(_ref, "gotoServicing", false), _ref;
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _inquiry.getList)({
        state: '2',
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
          _this.gotoServicing = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        state: '2',
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    //表格禁止勾选事件
    selectable: function selectable(row, index) {
      if (row.arbitratorName) {
        return true;
      } else {
        return false;
      }
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getSecretaryListInfo: function getSecretaryListInfo(data) {
      var _this3 = this;
      (0, _inquiry.getSecretaryList)({
        id: data
      }).then(function (res) {
        if (res.code == 1) {
          _this3.secretaryList = res.data[0].allReviews;
          _this3.partyChoiceArb.opinion = res.data[0].content;
          _this3.partyChoiceArb.secretaryName = _this3.secretaryList[0].name;
          _this3.partyChoiceArb.secretaryId = _this3.secretaryList[0].id;
        } else {
          _this3.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(function (error) {});
    },
    //已选择仲裁员
    getChoiceArbitrator: function getChoiceArbitrator(data) {
      var _this4 = this;
      (0, _inquiry.getChoiceArbitrator)(data).then(function (res) {
        if (res.code == 1) {
          _this4.partyChoiceArb.appArbitratorName = res.data.appArbitratorName;
          _this4.partyChoiceArb.resArbitratorName = res.data.resArbitratorName;
          _this4.partyChoiceArb.content = res.data.content;
          _this4.partyChoiceArb.replyName = res.data.replyName;
        }
      });
      this.getSecretaryListInfo(data);
    },
    handleReview: function handleReview(data) {
      var _this5 = this;
      this.caseId = data.caseId;
      this.allLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.sendCaseVisible = true;
      this.$nextTick(function () {
        _this5.$refs.sendCase.init(_this5.caseId, 2);
      });
    },
    //查看
    handleCheck: function handleCheck(data) {
      this.caseId = data.caseId;
      this.node = data.node;
      this.divisionVisible = true;
      this.getChoiceArbitrator(this.caseId);
    },
    updateShow: function updateShow() {
      this.divisionVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false;
      if (!this.fullscreenLoading) {
        this.fullscreenLoading = false;
      }
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    //进行组庭
    caseCourtAdopt: function caseCourtAdopt(divisionForm) {
      var _this6 = this;
      this.$refs[divisionForm].validate(function (valid) {
        if (valid) {
          _this6.gotoServicing = true;
          _this6.secretaryList.forEach(function (item) {
            if (item.id == _this6.partyChoiceArb.secretaryId) {
              _this6.partyChoiceArb.secretaryName = item.name;
            }
          });
          (0, _inquiry.procedureByAdopt)({
            caseId: _this6.caseId,
            node: _this6.node,
            reviewId: _this6.partyChoiceArb.secretaryId,
            reviewName: _this6.partyChoiceArb.secretaryName,
            content: _this6.partyChoiceArb.opinion
          }).then(function (res) {
            if (res.code == 1) {
              _this6.$message({
                message: '进行组庭成功',
                type: 'success'
              });
              _this6.divisionVisible = false;
              _this6.gotoServicing = false;
              _this6.init();
            }
          });
        } else {
          _this6.gotoServicing = false;
        }
      });
    },
    //批量审核
    batchReview: function batchReview() {
      var _this7 = this;
      this.caseNum = this.multipleSelectionId.length;
      this.$confirm('确定批量审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.multipleSelectionId = [];
        _this7.multipleSelection.forEach(function (item) {
          _this7.multipleSelectionId.push(item.caseId);
        });
        (0, _inquiry.batchProcedureByAdopt)({
          caseIds: _this7.multipleSelectionId
        }).then(function (res) {
          if (res.code == 1) {
            _this7.$message({
              message: '批量审核成功',
              type: 'success'
            });
            _this7.init();
          } else {
            _this7.$message.error(res.msg);
          }
        });
      });
    },
    batchReviewSubmit: function batchReviewSubmit(divisionLotForm) {
      var _this8 = this;
      this.$refs[divisionLotForm].validate(function (valid) {
        if (valid) {
          _this8.arbitratorList.forEach(function (item) {
            if (item.id == _this8.divisionLotForm.chiefArbitratorId) {
              _this8.divisionLotForm.chiefArbitratorName = item.name;
            }
          });
          _this8.divisionLotForm.caseIds = _this8.multipleSelectionId;
          (0, _inquiry.batchSendCompose)(_this8.divisionLotForm).then(function (res) {
            if (res.code == 1) {
              _this8.$message({
                message: '批量审核成功',
                type: 'success'
              });
              _this8.divisionLotVisible = false;
              _this8.init();
              // 批量送达页面？？？
            } else {
              _this8.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      var _this9 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: '2',
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this10 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
            _this10.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: '2',
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this10.tableData = res.rows;
            _this10.total = res.total;
            _this10.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;