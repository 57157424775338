"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));
var _inquiry = require("@/api/inquiry.js");
var _commission = require("@/api/commission.js");
var _dictionary = require("@/config/dictionary");
var _index2 = require("@/utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "selectArb",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      tableData: [],
      total: 0,
      //总页数
      conditionData: "",
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      rejectAuditState: _dictionary.dictionary.rejectAudit,
      multipleSelection: [],
      multipleSelectionId: [],
      secretaryList: [],
      wayList: [{
        name: "书面审理",
        id: "1"
      }, {
        name: "线下开庭",
        id: "2"
      }, {
        name: "视频开庭",
        id: "3"
      }],
      selectArbVisible: false,
      //案件组庭弹窗显示
      selectArbForm: {
        //案件组庭数据
        chiefArbitratorId: "",
        chiefArbitratorName: "",
        secondArbitratorId: "",
        secondArbitratorName: "",
        thirdArbitratorId: "",
        thirdArbitratorName: "",
        examArbName: "",
        trialWay: "1",
        type: 2,
        composeType: "1"
      },
      selectArbRules: {
        chiefArbitratorId: [{
          required: true,
          message: "请选择首席仲裁员",
          trigger: "change"
        }],
        composeType: [{
          required: true,
          message: "请选择推荐组庭类型",
          trigger: "change"
        }]
      },
      dealform: {
        //案件处理弹框1数据
        secretaryId: "",
        opinion: "",
        secretaryName: "",
        remarks: ""
      },
      caseId: "",
      node: "",
      arbitratorList: [],
      partyChoiceArb: {
        appArbitratorName: "",
        resArbitratorName: ""
      },
      sendCaseVisible: false,
      gotoServicing: false,
      //选定仲裁员弹窗 按钮loading

      //等待接口对接，获取回退的人员信息
      backInfoMsg: "",
      rejectData: []
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;
      this.loading = true;
      (0, _inquiry.getList)({
        state: "22",
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code === 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
          _this.gotoServicing = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //秘书列表
    getSecretaryList: function getSecretaryList() {
      var _this2 = this;
      (0, _inquiry.getSecretaryList)({
        id: this.caseId
      }).then(function (res) {
        if (res.code === 1 && res.data) {
          _this2.secretaryList = res.data[0].allReviews;
          _this2.dealform.opinion = res.data[0].content;
          _this2.dealform.secretaryName = res.data[0].allReviews[0].name;
          _this2.dealform.secretaryId = res.data[0].allReviews[0].id;
        }
      }).catch(function (error) {});
    },
    //已选择仲裁员
    getChoiceArbitrator: function getChoiceArbitrator(data) {
      var _this3 = this;
      (0, _inquiry.getChoiceArbitrator)(data).then(function (res) {
        if (res.code === 1 && res.data) {
          _this3.partyChoiceArb = res.data;
          if (res.data.chiefArbitratorId) {
            _this3.selectArbForm.chiefArbitratorId = res.data.chiefArbitratorId;
            _this3.selectArbForm.chiefArbitratorName = res.data.chiefArbitratorName;
            _this3.selectArbForm.examArbName = res.data.chiefArbitratorName;
          }
          if (res.data.secondArbitratorId) {
            _this3.selectArbForm.secondArbitratorId = res.data.secondArbitratorId;
            _this3.selectArbForm.secondArbitratorName = res.data.secondArbitratorName;
          }
          if (res.data.thirdArbitratorId) {
            _this3.selectArbForm.thirdArbitratorId = res.data.thirdArbitratorId;
            _this3.selectArbForm.thirdArbitratorName = res.data.thirdArbitratorName;
          }
          if (res.data.composeType) {
            _this3.selectArbForm.composeType = res.data.composeType + "";
          }
          if (res.data.trialWay) {
            _this3.selectArbForm.trialWay = res.data.trialWay;
          }
        }
      });
    },
    //仲裁员列表
    getArbitratorList: function getArbitratorList() {
      var _this4 = this;
      (0, _inquiry.getArbitratorList)().then(function (res) {
        if (res.code === 1) {
          _this4.arbitratorList = res.data;
        }
      });
    },
    //批量审核
    batchReview: function batchReview() {
      var _this5 = this;
      this.caseNum = this.multipleSelectionId.length;
      this.$confirm("确定批量审核？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this5.multipleSelectionId = [];
        _this5.multipleSelection.forEach(function (item) {
          _this5.multipleSelectionId.push(item.caseId);
        });
        (0, _inquiry.batchProcedureByAdopt)({
          caseIds: _this5.multipleSelectionId
        }).then(function (res) {
          if (res.code == 1) {
            _this5.$message({
              message: "批量审核成功",
              type: "success"
            });
            _this5.init();
          } else {
            _this5.$message.error(res.msg);
          }
        });
      });
    },
    updateShow: function updateShow() {
      this.selectArbVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false;
      // if(!this.fullscreenLoading){
      //   this.fullscreenLoading=false;
      // }
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    //审核
    handleReview: function handleReview(data) {
      this.caseId = data.caseId;
      this.node = data.node;
      this.selectArbForm.chiefArbitratorId = "";
      this.selectArbForm.secondArbitratorId = "";
      this.selectArbForm.thirdArbitratorId = "";
      this.rejectData = data.examineList || [];
      this.getChoiceArbitrator(this.caseId);
      this.getArbitratorList();
      this.getSecretaryList();
      this.selectArbVisible = true;
    },
    //弹窗确认按钮
    gotoService: function gotoService() {
      var _this6 = this;
      this.$refs["selectArbForm"].validate(function (valid) {
        if (valid) {
          _this6.gotoServicing = true;
          _this6.arbitratorList.forEach(function (item) {
            if (item.id === _this6.selectArbForm.chiefArbitratorId) {
              _this6.selectArbForm.chiefArbitratorName = item.name;
            } else if (item.id === _this6.selectArbForm.secondArbitratorId) {
              _this6.selectArbForm.secondArbitratorName = item.name;
            } else if (item.id === _this6.selectArbForm.thirdArbitratorId) {
              _this6.selectArbForm.thirdArbitratorName = item.name;
            }
          });
          _this6.secretaryList.forEach(function (item) {
            if (item.id === _this6.dealform.secretaryId) {
              _this6.dealform.secretaryName = item.name;
            }
          });
          _this6.selectArbForm.caseId = _this6.caseId;
          (0, _commission.commChoiceArbitrator)(_this6.selectArbForm).then(function (res) {
            if (res.code === 1) {
              (0, _inquiry.procedureByAdopt)({
                caseId: _this6.caseId,
                node: _this6.node,
                content: _this6.dealform.opinion,
                reviewId: _this6.dealform.secretaryId,
                reviewName: _this6.dealform.secretaryName
              }).then(function (res) {
                if (res.code === 1) {
                  _this6.$message({
                    message: "指定仲裁员成功",
                    type: "success"
                  });
                  _this6.selectArbVisible = false;
                  _this6.gotoServicing = false;
                  _this6.init();
                }
              });
            } else {
              _this6.gotoServicing = false;
              _this6.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this6.gotoServicing = false;
          });
        } else {
          return false;
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this7 = this;
      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        state: 22,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this7.tableData = res.rows;
          _this7.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this8 = this;
      this.pageLimit = val;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.state = "22";
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: val,
          page: this.currentPage,
          state: "22"
        }).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this9 = this;
      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.state = "22";
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: this.pageLimit,
          page: val,
          state: "22"
        }).then(function (res) {
          if (res.code == 1) {
            _this9.tableData = res.rows;
            _this9.total = res.total;
            _this9.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;