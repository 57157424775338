"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
var _inquiry = require("@/api/inquiry");
var _loading = require("@/utils/loading");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  data: function data() {
    return {
      caseServiceVisble: false,
      // loading: false,
      caseServiceForm: {
        serviceType: '',
        appPartyIds: [],
        appSendWay: [],
        resPartyIds: [],
        resSendWay: []
      },
      appSendway: {
        appSms: '',
        appEmail: ''
      },
      resSendway: {
        resSms: '',
        resEmail: ''
      },
      fileData: {
        appPartyList: '',
        resPartyList: '',
        appFileList: '',
        resFileList: '',
        appSms: '',
        resSms: ''
      },
      caseId: '',
      state: '',
      appPartyList: '',
      //申请方名字列表
      resPartyList: '',
      //被申请方名字列表
      lastArr: [],
      //上次的选择
      sending: false //点击确认发送 按钮时的状态ing
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init(caseId, state, tails, reviewData) {
      var _this = this;
      this.caseId = caseId;
      this.state = state;
      this.tails = tails;
      this.reviewData = reviewData;
      // this.caseServiceVisble = true;
      if (state == '12' || state == '15' || state == '14') {
        //立案
        (0, _inquiry.createRegisterFile)(caseId).then(function (res) {
          if (res.code == 1) {
            _this.fileData = res.data;
            _this.$emit('handleShowing');
            _this.caseServiceVisble = true;
            _this.dealSmsEmail();
          } else {
            _this.$emit('handleErrorShowing');
            _this.caseServiceVisble = false;
            _this.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this.$emit('handleErrorShowing');
          _this.caseServiceVisble = false;
        });
      } else if (state == '2') {
        //组庭
        (0, _inquiry.createComposeFile)(caseId).then(function (res) {
          if (res.code == 1) {
            _this.fileData = res.data;
            _this.$emit('handleShowing');
            _this.caseServiceVisble = true;
            _this.dealSmsEmail();
            if (_this.$parent.allLoading) {
              _this.$parent.allLoading.close();
            }
          } else {
            _this.$emit('handleErrorShowing');
            _this.caseServiceVisble = false;
            _this.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this.$emit('handleErrorShowing');
          _this.caseServiceVisble = false;
        });
      } else if (state == '3') {
        //开庭
        (0, _inquiry.creatCourtFile)(caseId).then(function (res) {
          if (res.code == 1) {
            _this.fileData = res.data;
            _this.$emit('handleShowing');
            _this.caseServiceVisble = true;
            _this.dealSmsEmail();
          } else {
            _this.$emit('handleErrorShowing');
            _this.caseServiceVisble = false;
            _this.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this.$emit('handleErrorShowing');
          _this.caseServiceVisble = false;
        });
      } else if (state == '41') {
        //裁决
        (0, _inquiry.createRuleSendFile)(caseId).then(function (res) {
          if (res.code == 1) {
            _this.fileData = res.data;
            _this.$emit('handleShowing');
            _this.caseServiceVisble = true;
            _this.dealSmsEmail();
          } else {
            _this.$emit('handleErrorShowing');
            _this.caseServiceVisble = false;
            _this.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this.$emit('handleErrorShowing');
          _this.caseServiceVisble = false;
        });
      } else if (state == '10') {
        //变更仲裁请求
        (0, _inquiry.createClaimChangeFile)({
          caseId: caseId,
          state: reviewData.reviewState,
          //状态：0 未审核 1 审核通过 -1 审核不通过
          reason: reviewData.reason
        }).then(function (res) {
          if (res.code == 1) {
            _this.fileData = res.data;
            _this.$emit('handleShowing');
            _this.caseServiceVisble = true;
            _this.dealSmsEmail();
          } else {
            _this.$emit('handleErrorShowing');
            _this.caseServiceVisble = false;
            _this.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this.$emit('handleErrorShowing');
          _this.caseServiceVisble = false;
        });
      }
    },
    //申请方人 勾选事件
    appPartyChange: function appPartyChange(val) {},
    //被申请方人 勾选事件
    resPartyChange: function resPartyChange(val) {},
    // 仲裁庭初始化
    otherInit: function otherInit(data, caseId, state) {
      this.fileData = data;
      this.state = state;
      this.caseId = caseId;
      this.$emit('handleShowing');
      this.caseServiceVisble = true;
      this.dealSmsEmail();
    },
    //处理短信邮件信息根据勾选人展示
    dealSmsEmail: function dealSmsEmail() {
      var _this2 = this;
      this.appSendway.appSms = '';
      this.appSendway.appEmail = '';
      this.resSendway.resSms = '';
      this.resSendway.resEmail = '';
      this.fileData.appPartyList.forEach(function (item, index) {
        _this2.appSendway.appSms += item.phone + '  ';
        _this2.appSendway.appEmail += item.email + '  ';
      });
      this.fileData.resPartyList.forEach(function (item, index) {
        _this2.resSendway.resSms += item.phone + '  ';
        _this2.resSendway.resEmail += item.email + '  ';
      });
    },
    //立案送达
    handleSendCase: function handleSendCase() {
      var _this3 = this;
      this.caseServiceForm.caseId = this.caseId;
      this.caseServiceForm.serviceType = '1';
      // 配置送达方式
      // showFullScreenLoading('.caseServiceDialog');
      // showFullScreenLoading('.caseServiceDialog')
      this.sending = true;
      (0, _inquiry.addSendService)(this.caseServiceForm).then(function (res) {
        if (res.code == 1) {
          (0, _inquiry.sendRegister)({
            caseId: _this3.caseId
          }).then(function (res) {
            // hideFullScreenLoading('.caseServiceDialog');
            if (res.code == 1) {
              _this3.sending = false;
              _this3.$message({
                message: '保存成功',
                type: 'success'
              });
              _this3.caseServiceVisble = false;
              _this3.$router.push({
                name: _this3.tails ? _this3.tails : "handing"
              });
            } else {
              _this3.sending = false;
              _this3.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this3.sending = false;
          });
        } else {
          _this3.sending = false;
          _this3.$message.error(res.msg);
        }
      }).catch(function (error) {
        _this3.sending = false;
      });
    },
    //立案送达查看
    showSendCase: function showSendCase() {
      this.sending = false;
      this.caseServiceVisble = false;
    },
    //组庭送达
    handleSendCompose: function handleSendCompose() {
      var _this4 = this;
      this.caseServiceForm.caseId = this.caseId;
      this.caseServiceForm.serviceType = '2';
      // 配置送达方式
      // showFullScreenLoading('.caseServiceDialog');
      // showFullScreenLoading('.caseServiceDialog')
      this.sending = true;
      // this.sending = false;
      (0, _inquiry.addSendService)(this.caseServiceForm).then(function (res) {
        if (res.code == 1) {
          (0, _inquiry.sendRegister)({
            caseId: _this4.caseId
          }).then(function (res) {
            // hideFullScreenLoading('.caseServiceDialog');
            if (res.code == 1) {
              _this4.sending = false;
              _this4.$message({
                message: '保存成功',
                type: 'success'
              });
              _this4.caseServiceVisble = false;
              _this4.$emit('initList');
            } else {
              _this4.sending = false;
              _this4.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this4.sending = false;
          });
        } else {
          _this4.sending = false;
          _this4.$message.error(res.msg);
          // hideFullScreenLoading('.caseServiceDialog');
        }
      }).catch(function (error) {
        _this4.sending = false;
      });
    },
    //开庭送达
    handleSendCourt: function handleSendCourt() {
      var _this5 = this;
      this.caseServiceForm.caseId = this.caseId;
      this.caseServiceForm.serviceType = '3';
      // 配置送达方式
      // showFullScreenLoading('.caseServiceDialog');
      // showFullScreenLoading('.caseServiceDialog')
      this.sending = true;
      (0, _inquiry.addSendService)(this.caseServiceForm).then(function (res) {
        if (res.code == 1) {
          (0, _inquiry.sendCourt)(_this5.caseId).then(function (res) {
            // hideFullScreenLoading('.caseServiceDialog');
            if (res.code == 1) {
              _this5.sending = false;
              _this5.$message({
                message: '保存成功',
                type: 'success'
              });
              _this5.caseServiceVisble = false;
              _this5.$emit('initList');
              // window.location.reload();
            } else {
              _this5.sending = false;
              _this5.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this5.sending = false;
          });
        } else {
          _this5.sending = false;
          _this5.$message.error(res.msg);
          // hideFullScreenLoading('.caseServiceDialog');
        }
      }).catch(function (error) {
        _this5.sending = false;
      });
    },
    //裁决送达
    handleSendRule: function handleSendRule() {
      var _this6 = this;
      this.caseServiceForm.caseId = this.caseId;
      this.caseServiceForm.serviceType = '4';
      // 配置送达方式
      // showFullScreenLoading('.caseServiceDialog');
      // showFullScreenLoading('.caseServiceDialog')
      this.sending = true;
      (0, _inquiry.addSendService)(this.caseServiceForm).then(function (res) {
        if (res.code == 1) {
          (0, _inquiry.sendRegister)({
            caseId: _this6.caseId
          }).then(function (res) {
            // hideFullScreenLoading('.caseServiceDialog');
            if (res.code == 1) {
              _this6.sending = false;
              _this6.$message({
                message: '保存成功',
                type: 'success'
              });
              _this6.caseServiceVisble = false;
              _this6.$emit('initList');
              // window.location.reload();
            } else {
              _this6.sending = false;
              _this6.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this6.sending = false;
          });
        } else {
          _this6.sending = false;
          _this6.$message.error(res.msg);
          // hideFullScreenLoading('.caseServiceDialog');
        }
      }).catch(function (error) {
        _this6.sending = false;
      });
    },
    // 仲裁庭取证送达
    handleSendEvident: function handleSendEvident() {
      var _this7 = this;
      this.caseServiceForm.caseId = this.caseId;
      this.caseServiceForm.serviceType = '6';
      this.sending = true;
      (0, _inquiry.addSendService)(this.caseServiceForm).then(function (res) {
        if (res.code == 1) {
          (0, _inquiry.sendEvident)({
            id: _this7.fileData.id
          }).then(function (res) {
            if (res.code == 1) {
              _this7.sending = false;
              _this7.$message({
                message: '送达成功',
                type: 'success'
              });
              _this7.caseServiceVisble = false;
              _this7.$emit('initList');
            } else {
              _this7.sending = false;
              _this7.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this7.sending = false;
          });
        } else {
          _this7.sending = false;
          _this7.$message.error(res.msg);
        }
      }).catch(function (error) {
        _this7.sending = false;
      });
    },
    // 变更仲裁请求送达
    handleSendChange: function handleSendChange() {
      var _this8 = this;
      this.caseServiceForm.caseId = this.caseId;
      this.caseServiceForm.serviceType = '10';
      this.sending = true;
      (0, _inquiry.addSendService)(this.caseServiceForm).then(function (res) {
        if (res.code == 1) {
          (0, _inquiry.sendClaimChangeFile)({
            caseId: _this8.caseId,
            state: _this8.reviewData.reviewState,
            reason: _this8.reviewData.reason
          }).then(function (res) {
            if (res.code == 1) {
              _this8.sending = false;
              _this8.$message({
                message: '送达成功',
                type: 'success'
              });
              _this8.caseServiceVisble = false;
              _this8.$emit('initList');
            } else {
              _this8.sending = false;
              _this8.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this8.sending = false;
          });
        } else {
          _this8.sending = false;
          _this8.$message.error(res.msg);
        }
      }).catch(function (error) {
        _this8.sending = false;
      });
    },
    //申请方送达人 勾选事件
    appPartyIdsChange: function appPartyIdsChange(val) {
      var _this9 = this;
      this.appSendway.appSms = '';
      this.appSendway.appEmail = '';
      var indexArr1 = [];
      val.forEach(function (item, index) {
        indexArr1.push(_this9.fileData.appPartyList.findIndex(function (ele) {
          return ele.partyId == item;
        }));
      });
      indexArr1.sort(function (a, b) {
        return a - b;
      });
      indexArr1.forEach(function (item) {
        _this9.appSendway.appSms += _this9.fileData.appPartyList[item].phone + '  ';
        _this9.appSendway.appEmail += _this9.fileData.appPartyList[item].email + '  ';
      });
    },
    //被申请方受送达人 勾选事件
    resPartyIdsChange: function resPartyIdsChange(val) {
      var _this10 = this;
      this.resSendway.resSms = '';
      this.resSendway.resEmail = '';
      var indexArr2 = [];
      val.forEach(function (item, index) {
        indexArr2.push(_this10.fileData.resPartyList.findIndex(function (ele) {
          return ele.partyId == item;
        }));
      });
      indexArr2.sort(function (a, b) {
        return a - b;
      });
      indexArr2.forEach(function (item) {
        _this10.resSendway.resSms += _this10.fileData.resPartyList[item].phone + '  ';
        _this10.resSendway.resEmail += _this10.fileData.resPartyList[item].email + '  ';
      });
    },
    //取消按钮
    handleCancle: function handleCancle() {
      this.caseServiceVisble = false;
      this.$emit('handleCloseLoading');
      this.$emit('handleErrorShowing');
    }
  }
};
exports.default = _default;