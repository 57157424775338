"use strict";

var _interopRequireDefault = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("D:/work/code/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _router = _interopRequireWildcard(require("@/router"));
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _settings = _interopRequireDefault(require("@/settings"));
var state = {
  token: localStorage.getItem("token"),
  name: localStorage.getItem("name"),
  role: localStorage.getItem("role"),
  userID: localStorage.getItem("user_id"),
  //路由数据
  routers: _router.constantRoutes
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROLE: function SET_ROLE(state, role) {
    state.role = role;
  },
  SET_USER_ID: function SET_USER_ID(state, id) {
    state.userID = id;
  },
  SET_ROUTERS: function SET_ROUTERS(state, routers) {
    if (routers.length > 0) {
      state.routers = state.routers.concat(routers); //菜单显示
    } else {
      state.routers = _router.constantRoutes;
    }
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var account = userInfo.account,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        account: account.trim(),
        password: password,
        commId: _settings.default.commId
      }).then(function (response) {
        if (response.code == 1) {
          var data = response.data;
          commit("SET_TOKEN", data.token);
          commit("SET_NAME", data.name);
          commit("SET_ROLE", data.roles);
          commit("SET_USER_ID", data.id);
          (0, _auth.setToken)(data.token);
          localStorage.setItem("admin", JSON.stringify(data));
          localStorage.setItem("token", data.token);
          localStorage.setItem("name", data.name);
          localStorage.setItem("role", data.roles);
          localStorage.setItem("user_id", data.id);
          if (data.commissionInfoDto) {
            localStorage.setItem("title", data.commissionInfoDto.name);
            localStorage.setItem("logo", data.commissionInfoDto.logo);
            localStorage.setItem("commId", data.commId);
          } else {
            localStorage.setItem("title", _settings.default.title);
            localStorage.setItem("logo", "");
          }
          resolve(data);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  //手机短信登陆
  phoneLogin: function phoneLogin(_ref2, userInfo) {
    var commit = _ref2.commit;
    var mobile = userInfo.mobile,
      verifyNum = userInfo.verifyNum;
    return new Promise(function (resolve, reject) {
      (0, _user.loginByPhone)({
        mobile: mobile.trim(),
        code: verifyNum,
        commId: _settings.default.commId
      }).then(function (response) {
        if (response.code == 1) {
          var data = response.data;
          commit("SET_TOKEN", data.token);
          commit("SET_NAME", data.name);
          commit("SET_ROLE", data.roles);
          (0, _auth.setToken)(data.token);
          localStorage.setItem("admin", JSON.stringify(data));
          localStorage.setItem("token", data.token);
          localStorage.setItem("name", data.name);
          localStorage.setItem("role", data.roles);
          if (data.commissionInfoDto) {
            localStorage.setItem("title", data.commissionInfoDto.name);
            localStorage.setItem("logo", data.commissionInfoDto.logo);
          } else {
            localStorage.setItem("title", _settings.default.title);
            localStorage.setItem("logo", "");
          }
          resolve(data);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  //   getInfo({ commit, state }) {
  //     return new Promise((resolve, reject) => {
  //       getInfo(state.token).then(response => {
  //         const { data } = response
  //
  //         if (!data) {
  //           reject('Verification failed, please Login again.')
  //         }
  //
  //         const { name, avatar } = data
  //
  //         commit('SET_NAME', name)
  //         commit('SET_AVATAR', avatar)
  //         resolve(data)
  //       }).catch(error => {
  //         reject(error)
  //       })
  //     })
  //   },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _user.logout)(state.token).then(function () {
        commit("SET_TOKEN", "");
        commit("SET_NAME", "");
        commit("SET_ROLE", "");
        (0, _router.resetRouter)();
        commit("SET_ROUTERS", "");
        localStorage.setItem("admin", "");
        localStorage.setItem("token", "");
        localStorage.setItem("name", "");
        localStorage.setItem("role", "");
        localStorage.setItem("title", "");
        localStorage.setItem("logo", "");
        (0, _auth.removeToken)();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit("SET_TOKEN", "");
      commit("SET_NAME", "");
      commit("SET_ROLE", "");
      (0, _router.resetRouter)();
      localStorage.setItem("admin", "");
      localStorage.setItem("token", "");
      localStorage.setItem("name", "");
      localStorage.setItem("role", "");
      localStorage.setItem("title", "");
      localStorage.setItem("logo", "");
      (0, _auth.removeToken)();
      resolve();
    });
  },
  resetRouter: function resetRouter(_ref5, newRouter) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit("SET_ROUTERS", newRouter);
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;